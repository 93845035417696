import React, {useState} from "react"
import { Link, graphql } from "gatsby"
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from 'react-visibility-sensor';
import PopupForm from '../components/popupform'
import HelpForm from "../components/helpform"
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const EmployersPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
    } else {
      setIsPopupOpen(true);
    }
  }

  const handleRequesetClick = (e) => {
    e.preventDefault();
    togglePopup();
    typeof window !== "undefined" && window.gtag("event", "click", {   
        'event_category': "Request Demo",
        'event_label': "Demo Request",
        'value': 1 
      })
  }


  const handleClick = (e) => {
    e.preventDefault();
    togglePopup();
    typeof window !== "undefined" && window.gtag("event", "click", {   
        'event_category': "Get Started",
        'event_label': "Demo Request",
        'value': 1 
      })
  }

    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Corporate Partnerships" />
        <PopupForm isPopupOpen={isPopupOpen} onClickPopup={togglePopup} />
        <section className="uni-section about-hero">
            <div className="uni-container flex-space-between flex-vertical-reverse">
                <div className="uni-col">
                    <h1 className="uni-h1">Bring Sunset to your workplace</h1>
                    <p className="uni-hero-p">
                        Create a healthier, happier, and more productive 
                        workforce with Sunset Health’s industry leading burnout platform.
                    </p>
                    <button href="" className="uni-cta-btn btn" onClick={handleClick}>Get Started</button>
                </div>
                <img src={require('../../content/assets/hero-content/employers.svg')} alt="Employers image" className="uni-hero_img" />
            </div>
        </section>
        <section className="uni-section border">
            <div className="uni-h-wrap">
                <h4 className="emp-h4 emp-font-extrabold uni-banner-text text-align-center">Behavioral Health Reimagined</h4>
                <h5 className="emp-h5 emp-font-bold text-align-center"><span className="color-457B9D">Personalized roadmap to improve </span> <span className="color-255A7E"> mental wellness, burnout, and stress</span></h5>
                <p className="text-align-center">Sunset helps you and your team understand and reverse burnout through the proven science of behavior change.</p>
            </div>
        </section>
        <section className="uni-section bg-f0f6f9">
            <div className="uni-container flex-space-between digital-solution-vertical-center">
                <VisibilitySensor partialVisibility>
                    {({ isVisible }) => (
                        <Spring delay={100} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                            {({opacity, transform}) => (
                                <img src={require('../../content/assets/splash/app-display.svg')} className="emp-phone_img" style={{opacity, transform}} />
                            )}
                        </Spring>
                    )}
                </VisibilitySensor>
                <div className="uni-col emp-col">
                    <h2 className="uni-h2">A digital solution for reversing burnout</h2>
                    <p className="uni-p">
                        Sunset is an automated, intelligent companion for reversing chronic workplace stress, exhaustion, and cynicism - accessible from your smartphone.
                    </p>
                    <br/>
                    <p className="uni-p">
                        We deliver enterprise burnout risk assessments, real-time burnout monitoring, and burnout management tools to empower organizations and employees to achieve greatness
                    </p>
                </div>
            </div>
        </section>
        <section className="uni-section border">
            <div className="uni-container emp-why-sunset-grid flex-space-between">
                <div className="uni-col why-sunset-col">
                    <h2 className="uni-h2">Why choose Sunset for your business</h2>
                    <p className="uni-p">
                    We know that employees make up the backbone of an organization. Helping employees improve their well-being and mental health only leads to better results
                    </p>
                    <ul className="emp-feature-list clear-spacing">
                        <li className="emp-why-item">
                            <img src={require('../../content/assets/icons/check.png')} alt="Focus on what matters" width="24" className="emp-feature_img" />
                            <div className="text-box-hold">
                                <h5 className="emp-h5 emp-why-h5">Easy Rollout</h5>
                            </div>
                        </li>
                        <li className="emp-why-item">
                            <img src={require('../../content/assets/icons/check.png')} alt="Take the actions that work" width="24" className="emp-feature_img" />
                            <div className="text-box-hold">
                                <h5 className="emp-h5 emp-why-h5">Detailed Reporting</h5>
                            </div>
                        </li>
                        <li className="emp-why-item">
                            <img src={require('../../content/assets/icons/check.png')} alt="Take the actions that work" width="24" className="emp-feature_img" />
                            <div className="text-box-hold">
                                <h5 className="emp-h5 emp-why-h5">Company Dynamic Customizability</h5>
                            </div>
                        </li>
                        <li className="emp-why-item">
                            <img src={require('../../content/assets/icons/check.png')} alt="Take the actions that work" width="24" className="emp-feature_img" />
                            <div className="text-box-hold">
                                <h5 className="emp-h5 emp-why-h5">Ongoing Support</h5>
                            </div>
                        </li>
                        <li className="emp-why-item">
                            <img src={require('../../content/assets/icons/check.png')} alt="Access your blocker" width="24" className="emp-feature_img" />
                            <div className="text-box-hold">
                                <h5 className="emp-h5 emp-why-h5">Improved Productivity</h5>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="uni-col flex-vertical">
                    <img src={require('../../content/assets/splash/employers-team.jpg')} />
                </div>
            </div>
        </section>
        <section className="uni-section">
            <div className="uni-container">
                <h2>Why employees request Sunset</h2>
                <br />
                <div className="emp-benefits-wrap" style={{ overflow: "hidden" }}>
                    <img src={require('../../content/assets/splash/collaboration.jpg')} className="ind-values-image" />
                    <div className="emp-benefits">
                        <VisibilitySensor partialVisibility>
                            {({ isVisible }) => (
                                <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(200px)", }}>
                                    {({opacity, transform}) => (
                                        <div className="emp-benefits-item emp-why-item bg-f0f6f9" style={{opacity, transform}}>
                                            <h4 className="emp-benefits-h4">Reduced stress</h4>
                                            <p className="uni-p emp-benefits-p">Develop daily habits that help break your stress cycle in the short and long term.</p>
                                        </div>
                                    )}
                                </Spring>
                            )}
                        </VisibilitySensor>
                        <VisibilitySensor partialVisibility>
                            {({ isVisible }) => (
                                <Spring delay={400} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(200px)", }}>
                                    {({opacity, transform}) => (
                                        <div className="emp-benefits-item emp-why-item bg-fdf3ee" style={{opacity, transform}}>
                                            <h4 className="emp-benefits-h4">Increase focus and creativity</h4>
                                            <p className="uni-p emp-benefits-p">Daily practices that clear your mind and help you regain clarity of your priorities.</p>
                                        </div>
                                    )}
                                </Spring>
                            )}
                        </VisibilitySensor>
                        <VisibilitySensor partialVisibility>
                            {({ isVisible }) => (
                                <Spring delay={600} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(200px)", }}>
                                    {({opacity, transform}) => (
                                        <div className="emp-benefits-item emp-why-item bg-fffaeb" style={{opacity, transform}}>
                                            <h4 className="emp-benefits-h4">Improve Sleep</h4>
                                            <p className="uni-p emp-benefits-p">Build a nightly routine that helps you ease into a restful night of sleep.</p>
                                        </div>
                                    )}
                                </Spring>
                            )}
                        </VisibilitySensor>            
                    </div>
                </div>
            </div>
        </section>
        <section className="uni-section emp-ready">
            <div className="div-block">
                <h3 className="emp-ready-h">Ready to start using Sunset Health today?</h3>
                <p className="paragraph-12">Schedule a demo with one of our representatives today.</p>
            </div>
            <div>
                <button className="uni-cta-btn btn" onClick={handleRequesetClick}>Request a Demo</button>
            </div>
        </section>
        <section className="uni-section request-form-section">
            <div className="uni-container flex-space-between flex-vertical">
                <div className="uni-col uni-form-instructions-col">
                    <h3 className="uni-h3">Interested in our corporate pilot program? Get in touch.</h3>
                    <br />
                    <p className="uni-p">Join leading companies using Sunset Health to better their workplace culture and business outcomes.</p>
                    <br />
                    <p className="uni-p">Please fill out the form and one our representatives we get back to you shortly.</p>
                </div>
                <div className="uni-col uni-form-col">
                    <HelpForm />
                </div>
            </div>
        </section>
      </Layout>
    )
}

export default EmployersPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
